<template>
  <!-- 添加编辑 -->
  <el-dialog
    :title="修改员工姓名"
    width="30%"
    v-model="dialogShow"
  >
    <el-form :model="form" :rules="nameRules" ref="levelRef">
      <el-row>
        <el-col :span="18">
          <el-form-item label="员工姓名" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18">
          <el-form-item label="服务地址" prop="address">
            <el-input
              type="textarea"
              rows="4"
              style="width: 600px"
              v-model="form.address"
            />
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="btnCancel"> 取消 </el-button>
        <el-button type="primary" @click="btnOk"> 确认 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { staffSaveAPI } from '@/api/account'
import { ElMessage } from 'element-plus'
const props = defineProps({
  // 接收 v-model
  modelValue: {
    type: Boolean,
    required: true
  },
  onListData: {
    type: Function
  },

  staffinfo: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改
watch(
  () => props.staffinfo,
  (val) => {
    form.value = val
  },
  {
    deep: true
  }
)

const form = ref({
  name: '',
  num: 0,
  address: ''
})

const nameRules = ref({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '员工姓名必填'
    }
  ],
  address: [
    {
      required: true,
      trigger: 'blur',
      message: '服务地址必填'
    }
  ]
})

// 获得 Ref 实例
const levelRef = ref(null)
// 取消
const btnCancel = () => {
  dialogShow.value = false
}

// 确认
const btnOk = () => {
  levelRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 触发添加 api
      console.log(Number(form.value.id))
      if (Number(form.value.id)) {
        await staffSaveAPI(form.value)
        ElMessage.success('姓名修改成功。')
      }
      props.onListData()
    } catch (error) {
      console.log(error)
    } finally {
      dialogShow.value = false
    }
  })
}
</script>

<style></style>
