import request from '@/utils/request'

// 员工管理
// 员工列表
export const staffListAPI = (params) => {
  return request({
    url: '/staffList',
    method: 'GET',
    params
  })
}

// 冻结员工
export const deletestaffAPI = (params) => {
  return request({
    url: '/staffDel',
    method: 'GET',
    params
  })
}
// 启用员工
export const startstaffAPI = (params) => {
  return request({
    url: '/staffStart',
    method: 'GET',
    params
  })
}

// 审核员工
export const shenheAPI = (data) => {
  return request({
    url: '/shenhe',
    method: 'POST',
    data
  })
}

// 员工详情
export const staffInfoAPI = (params) => {
  return request({
    url: '/staffInfo',
    method: 'GET',
    params
  })
}

// 修改员工姓名
export const staffSaveAPI = (data) => {
  return request({
    url: '/staffSave',
    method: 'POST',
    data
  })
}
